import React from "react";
import { useState, useEffect } from "react";

const DropArtists = (drop_tokens = []) => {
  const [artistsList, setArtistsList] = useState([]);

  useEffect(() => {
    //Get artist name from the tokens
    const artists = [];

    drop_tokens.drop_tokens.forEach((token) => {
      token.artists.forEach((artist) => {
        const artistFullname = `${artist.first_name} ${artist.surname}`;

        if (!artists.includes(artistFullname)) {
          artists.push(artistFullname);
        }
        setArtistsList(artists);
      });
    });
  }, [drop_tokens]);

  //artistsList

  return artistsList.length === 0 ? (
    <></>
  ) : (
    <div>
      <ul className="flex gap-x-1">
        {artistsList.map((element, index) => {
          return (
            <li key={element} className="text-primary">
              {element}
              {index < artistsList.length - 1 && ", "}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default DropArtists;
