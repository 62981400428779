import { Link } from "gatsby";
import React from "react";
import CardButton from "./CardButton";
import CountDown from "./CountDown";

const NftHeroCardTime = ({
  name,
  nftCardImg,
  slug,
  collectionSlug,
  days,
  hours,
  minutes,
  seconds,
}) => {
  return (
    <>
      <div className="min-w-[290px] max-w-[420px] mx-auto sm:mx-0 w-full">
        <div className="relative">
          <div
            className="w-full h-[550px] bg-center bg-cover"
            style={{ backgroundImage: `url(${nftCardImg})` }}
          ></div>
          <div className="absolute bottom-14 left-0 w-full bg-black bg-opacity-70 p-4 z-30">
            <h3 className="text-center text-2xl font-bold mb-2 capitalize">
              {name}
            </h3>
            <p className="text-center text-sm font-semibold mb-2">
              Avaiable in
            </p>
            <CountDown
              days={days}
              hours={hours}
              minutes={minutes}
              seconds={seconds}
            />
          </div>
          <div className="absolute bottom-0 left-0 w-full z-40">
            <Link to={`/marketplace/nfts/${collectionSlug}/${slug}`}>
              <CardButton text="View" />
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default NftHeroCardTime;
