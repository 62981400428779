import React, { useContext, useEffect, useState } from "react";
import AppContext from "../../context/AppContext";
import DropLine from "./DropLine";

const DropLines = ({ dropList = [], currentTime, ...props }) => {
  const { scrollToDropId, updateScrollToDropId } = useContext(AppContext);
  const [sortedDropList, setSortedDropList] = useState([]);

  useEffect(() => {
    if (sortedDropList.length && scrollToDropId) {
      const dropElement = document.getElementById(scrollToDropId);
      setTimeout(() => {
        if (dropElement) {
          dropElement?.scrollIntoView();
          updateScrollToDropId("");
        }
      }, 500);
    }
  }, [sortedDropList, scrollToDropId, updateScrollToDropId]);

  useEffect(() => {
    if (dropList.length && currentTime) {
      const currentDate = new Date(currentTime);
      const live = dropList.filter((drop) => {
        return (
          new Date(drop.drop_end) > currentDate &&
          new Date(drop.drop_start) < currentDate
        );
      });
      live.reverse();
      const yetToStart = dropList.filter((drop) => {
        return new Date(drop.drop_start) > currentDate;
      });
      yetToStart.reverse();
      const newDropsList = [...live, ...yetToStart];
      setSortedDropList(newDropsList);
    }
  }, [dropList, currentTime]);
  return (
    <>
      <ul>
        {sortedDropList.map((element) => {
          //Need to add date related items. Cards need to change depending on date status
          if (element.aspen_tokens.length !== 0) {
            return (
              <DropLine
                key={element.id}
                element={element}
                currentTime={currentTime}
              />
            );
          }
          return true;
        })}
      </ul>
    </>
  );
};

export default DropLines;
