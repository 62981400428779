import React, { useEffect, useState } from "react";
import DropArtists from "../molecules/DropArtists";
import TwoColorHeader from "../atoms/TwoColorHeader";
import NftGridLayout from "../molecules/NftGridLayout";
import NftHeroCardTime from "../molecules/nftCards/NftHeroCardTime";
import NftHeroCardRemaining from "../molecules/nftCards/NftHeroCardRemaining";
import useDropCalculations from "../../hooks/useDropCalculations";
import useCountdown from "../../hooks/useCountdown";

const DropLine = ({ element, currentTime }) => {
  const [dropStarted, setDropStarted] = useState(false);
  const [yetToStart, expired] = useDropCalculations(
    element.drop_start,
    element.drop_end,
    currentTime
  );

  useEffect(() => {
    if (!yetToStart) {
      setDropStarted(true);
    }
  }, [yetToStart]);

  const [days, hours, minutes, seconds] = useCountdown(
    element?.drop_start,
    currentTime,
    yetToStart
  );

  if (days + hours + minutes + seconds === 0) {
    setDropStarted(true);
  }

  if (expired) return null;

  return (
    <>
      <li className="mt-24 mb-16" id={element.id}>
        <TwoColorHeader title={element.name} />

        <div className="flex justify-center items-center mt-2 gap-x-2">
          <span className="text-gray">By:</span>
          <DropArtists drop_tokens={element.aspen_tokens} />
        </div>

        <div className="flex justify-center">
          <div className="max-w-3xl mt-6 text-gray">{element.description}</div>
        </div>

        <NftGridLayout contentLength={element.aspen_tokens.length}>
          {element.aspen_tokens.map((item) => {
            if (!dropStarted) {
              return (
                <NftHeroCardTime
                  key={item.id}
                  name={item.name}
                  nftCardImg={item.featured_image?.url}
                  slug={item.slug}
                  collectionSlug={
                    item.aspen_collection?.artefy_collection?.slug
                  }
                  days={days}
                  hours={hours}
                  minutes={minutes}
                  seconds={seconds}
                />
              );
            } else {
              return (
                <NftHeroCardRemaining
                  key={item.id}
                  name={item.name}
                  nftCardImg={item.featured_image?.url}
                  slug={item.slug}
                  collectionSlug={
                    item.aspen_collection?.artefy_collection?.slug
                  }
                  item={item}
                />
              );
            }
          })}
        </NftGridLayout>
      </li>
    </>
  );
};

export default DropLine;
