import * as React from "react";
import { useState, useEffect } from "react";
import Layout from "../../components/Layout";
import StaticBanner from "../../components/molecules/StaticBanner";

import heavymetalbanner from "../../assets/images/heavymetalbanners.png";

import DropLines from "../../components/organisms/DropLines";

import Container from "@mui/material/Container";

import urlConstants from "../../constants/urlConstants.js";
import Loading from "../../components/molecules/Loading";
import { getter } from "../../services/apiService.js";
import { Helmet } from "react-helmet";

//WORLD_ID

//get(url, form = { getHeaders: () => { return {} } }){

const IndexPage = ({ pageContext }) => {
  /**
  Get active NFT drops
  This needs to be a real time request to the api
*/

  const [dropList, setDropList] = useState([]);
  const [now, setNow] = useState(null);
  const [showLoading, setShowLoading] = useState(false);
  const pageName = "Limited NFT Drops";

  useEffect(() => {
    setShowLoading(true);
    getter(urlConstants.getLatestDropsUrl)
      .then((response) => {
        if (response) {
          setDropList(response.data.drop);
          setNow(response.data.systemNow);
        } else {
          setDropList([]);
        }

        setShowLoading(false);
      })
      .catch((err) => {
        console.log("error occured", err);
        setShowLoading(false);
      });
  }, []);

  const bannerData = {
    title: "Active |NFT Drops",
    subtext: "Exclusive drops from top artists",
    logo: {
      url: "",
    },
    image: {
      url: heavymetalbanner,
    },
  };

  return (
    <Layout>
      <Helmet>
        <meta
          name="title"
          content={`${process.env.GATSBY_SITE_NAME} | ${pageName}`}
        />
        <meta
          name="description"
          content={`${process.env.GATSBY_SITE_NAME} | ${pageName}`}
        />

        {/* OpenGraph tags */}
        <meta
          property="og:title"
          content={`${process.env.GATSBY_SITE_NAME} | ${pageName}`}
        />
        <meta
          property="og:description"
          content={`${process.env.GATSBY_SITE_NAME} | ${pageName}`}
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://artefy-strapi-boilerplate-4mdevelopment.s3.ap-southeast-2.amazonaws.com/metal_plus_97826b79e2.png"
        />
        <meta property="og:image:width" content="400" />
        <meta property="og:image:height" content="50" />

        {/* Twitter Card tags */}
        <meta
          name="twitter:card"
          content={`${process.env.GATSBY_SITE_NAME} | ${pageName}`}
        />
        <meta
          name="twitter:creator"
          content={`${process.env.GATSBY_TWITTER_HANDLE}`}
        />
        <meta
          name="twitter:title"
          content={`${process.env.GATSBY_SITE_NAME} | ${pageName}`}
        />
        <meta
          name="twitter:description"
          content={`${process.env.GATSBY_SITE_NAME} | ${pageName}`}
        />
        <title>{`${process.env.GATSBY_SITE_NAME} | ${pageName}`}</title>
      </Helmet>
      <div>
        <StaticBanner
          bannerData={bannerData}
          useLogo={false}
          useAssetUrl={false}
          extraClasses="mt-20 md:mt-24"
          sizeClass="text-5xl"
          dropPage={true}
        />

        <Container maxWidth="xl" className="mt-16 mb-16 text-center">
          <Loading showLoading={showLoading} text="getting drops">
            <DropLines dropList={dropList} currentTime={now} />
          </Loading>
        </Container>
      </div>
    </Layout>
  );
};

export default IndexPage;
