import React from "react";

const CountDown = ({ days, hours, minutes, seconds }) => {
  const values = [
    { value: days, type: "days" },
    { value: hours, type: "hours" },
    { value: minutes, type: "mins" },
    { value: seconds, type: "sec" },
  ];
  return (
    <>
      <div className="flex">
        {values.map((item) => {
          return (
            <div
              key={item.type}
              className="flex flex-col justify-center items-center w-1/4 px-2"
            >
              <div className="w-full py-4 bg-black border border-mutedGray text-2xl font-bold text-redVariant rounded-md text-center">
                {item.value}
              </div>
              <span className="font-medium text-xs">{item.type}</span>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default CountDown;
