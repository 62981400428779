import axios from "axios";
import { Link } from "gatsby";
import React, { useEffect, useState } from "react";
import CardButton from "./CardButton";

const NftHeroCardRemaining = ({
  name,
  nftCardImg,
  slug,
  collectionSlug,
  item,
}) => {
  const [remainingCards, setRemainingCards] = useState(0);
  const [loading, setLoading] = useState(false);

  const tokenId = item?.id;
  useEffect(() => {
    if (tokenId) {
      setLoading(true);
      axios
        .get(
          `${process.env.GATSBY_SHARED_STRAPI_BASE_URL}/api/selling/getLinesCount/${tokenId}`
        )
        .then((response) => {
          const cardCount = response.data.genesis_drop_available;
          setRemainingCards(cardCount);
          setLoading(false);
        })
        .catch((err) => {
          console.log("some error occured:", err);
          setLoading(false);
        });
    }
  }, [tokenId]);

  return (
    <>
      <div className="min-w-[290px] max-w-[420px] mx-auto sm:mx-0 w-full">
        <div className="relative">
          <div
            className="w-full h-[550px] bg-center bg-cover"
            style={{ backgroundImage: `url(${nftCardImg})` }}
          ></div>
          <div className="absolute bottom-14 left-0 w-full bg-black bg-opacity-70 p-4 z-30">
            <h3 className="text-center text-2xl font-bold mb-2 capitalize">
              {name}
            </h3>
            <p className="text-center text-sm font-semibold mb-2 uppercase text-sm font-semibold text-red">
              DROP IS LIVE
            </p>
            <div className="flex gap-x-2 justify-center items-center">
              <div className="p-4 bg-black border border-mutedGray text-2xl font-bold text-redVariant rounded-md text-center">
                {loading ? (
                  <div className="loader !w-4 !h-4"></div>
                ) : (
                  remainingCards
                )}
              </div>
              <span>remaining copies</span>
            </div>
          </div>
          <div className="absolute bottom-0 left-0 w-full z-40">
            <Link to={`/marketplace/nfts/${collectionSlug}/${slug}`}>
              <CardButton text="View" />
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default NftHeroCardRemaining;
