import React from "react";

const Loading = ({ showLoading = false, children, text }) => {
  return (
    <>
      {showLoading && (
        <div className="mx-auto w-full flex jusitfy-center items-center gap-y-8 flex-col">
          <div className="loader"></div>
          <span className="capitalize">{text}</span>
        </div>
      )}
      {!showLoading && <>{children}</>}
    </>
  );
};

export default Loading;
